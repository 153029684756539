.information-heading {
    font-size: 1.3rem;
}
.information-heading-text {
    font-size: 1rem;
    font-weight: lighter;
}
@media screen and (max-width: 441px) {
    .information-heading {
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
}