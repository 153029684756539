body {
  position: relative;
}
.panel-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #fcfcfc;
}
.container {
  width: 83%;
}
.panel {
  width: 85%;
  z-index: 2;
  margin: 100px 0;
}
.panel-column {
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 30px;
}
.log-out-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 140px;
  padding: 8px 0;
  z-index: 2;
}
.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 400px;
  height: 100%;
}
.bg-overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 1200px) {
  .panel {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .container {
    width: 100%;
    padding: 0 30px;
  }
}
